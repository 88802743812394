export const MenuList = [
  // Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-title",
    iconStyle: <i className="la la-pie-chart"></i>,
    to: "dashboard",
    text: "dashboard",
  },
  // Admins
  {
    title: "Admins Management",
    classsChange: "mm-title",
    iconStyle: <i className="la la-user-shield"></i>,
    to: "admins",
    text: "admins",
  },
  // Rules
  {
    title: "Rules",
    classsChange: "mm-title",
    iconStyle: <i className="la la-shield"></i>,
    to: "rules",
    text: "rules",
  },
  // Users
  {
    title: "Users",
    classsChange: "mm-title",
    iconStyle: <i className="la la-users"></i>,
    to: "users",
    text: "users",
  },
  // Categories
  {
    title:'Categories',
    classsChange: "mm-title",
    iconStyle: <i className="la la-cubes"></i>,
    text:'categories',
    to: 'categories',
  },
  // SubCategories
  {
    title: "SubCategories",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-cube"></i>,
    to: "sub-categories",
    text: "sub_categories",
  },
  // Brands
  {
    title:'Brands',
    classsChange: "mm-title",
    iconStyle: <i className="la la-slack"></i>,
    text:'brands',
    to: 'brands',
  },
  // Products
  {
    title:'Products',
    classsChange: "mm-title",
    iconStyle: <i className="la la-cube"></i>,
    text:'products',
    to: 'products',
  },
  // Variant
  {
    title:'Variant',
    classsChange: "mm-title",
    iconStyle: <i className="la la-simplybuilt"></i>,
    text:'variant',
    to: 'variant',
  },
  // Dynamic Variant
  {
    title:'Dynamic Variant',
    classsChange: "mm-title",
    iconStyle: <i className="la la-simplybuilt"></i>,
    text:'dynamic_variant',
    to: 'dynamic-variant',
  },
  // Orders
  {
    title: "Orders",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-truck"></i>,
    to: "orders",
    text: "orders",
  },
  // Orders Guest
  {
    title: "Orders Guest",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-truck"></i>,
    to: "orders-guest",
    text: "orders_guest",
  },
  // Promo Codes
  {
    title: "Promo Codes",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-dollar"></i>,
    to: "promo-codes",
    text: "promo_codes",
  },
  // Show Room
  {
    title: "Show Room",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-warehouse"></i>,
    to: "show-room",
    text: "show_room",
  },
  // Banners
  {
    title: "Banners",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-tv" />,
    to: "banners",
    text: "banners",
  },
  // Banners
  {
    title: "Showroom Banner",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-tv" />,
    to: "showroom-banner",
    text: "showroom_banner",
  },
  // Contact Us
  {
    title: "Contact Us",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-bell"></i>,
    text: "contact_us",
    to: "contact-us",
  },
  // Home
  // {
  //     title:'Home',
  //     text:'home',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="la la-slack" />,
  //     content : [
  //         {
  //             title:'Page',
  //             text:'page',
  //             to: 'home/page',
  //             rule: 'home',
  //         },
  //         {
  //             title:'Products',
  //             text:'products',
  //             to: 'home/products',
  //             rule: 'home',
  //         },
  //         {
  //             title: "Social Media",
  //             text: "social_media",
  //             to: "home/social",
  //             rule: "home",
  //           },
  //         {
  //           title: "Contact Us",
  //           text: "contact_us",
  //           to: "home/contact-us",
  //           rule: "home",
  //         },
  //         {
  //           title: "About Us",
  //           text: "about_us",
  //           to: "home/about-us",
  //           rule: "home",
  //         },
  //     ]
  // },
  // Setting
  {
      title:'Setting',
      text:'setting',
      classsChange: 'mm-collapse',
      iconStyle: <i className="la la-cog" />,
      content : [
          {
            title: "Delivery",
            text: "delivery",
            to: "delivery",
            rule: "delivery",
          },
          {
            title: "Social Media",
            text: "social_media",
            to: "social",
            rule: "social",
          },
          {
            title: "Payment",
            text: "payment",
            to: "payment",
            rule: "payment",
          },
          {
            title: "About Us",
            text: "about_us",
            to: "about-us",
            rule: "static_pages",
          },
      ]
  },
  // Social Media
  // {
  //   title: "Social Media",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-wrench"></i>,
  //   to: "home/social",
  //   text: "social_media",
  // },

  // <i className='la la-cubes'></i>,
  // <i className='la la-cube'></i>,
  // <i className="la la-slack" />,
  // <i className="flaticon-381-notepad" />,
  // <i className="la la-qrcode"></i>
  // <i className='la la-truck'></i>
  // <i className='la la-pie-chart'></i>
  // <i className='la la-dollar'></i>
  // <i className="la la-wrench" />
  // <i className="la la-simplybuilt" />
  // <i className='la la-bell'></i>
];
